import { cloneDeep } from 'lodash'

import { TrackingProps } from './tracking-events'
import { trackWithUserId, identify } from './index'

interface AnalyticsBaseEvent {
    type: string,
}

export abstract class AbstractAnalytics<TEvent extends AnalyticsBaseEvent> {
    public track(payload: TEvent): void {
        try {
            const { type, ... event } = payload
            trackWithUserId(
                type,
                cloneDeep(event),
            )
        } catch (error) {
            console.error(
                'Error when tracking action',
                error,
            )
        }
    }
    public identify(props: TrackingProps): void {
      try {
        identify(props)
      } catch (error) {
        console.error(
          'Error when tracking setting user',
          error,
      )}
    }
}
