import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify);

const theme: Record<string, unknown> = {
  white: '#fff',
  primaryText: '#626998',
  primaryLogoBlue: '#6D4FFF',
  link: '#666f9a',
  primaryActiveNotSelected: '#79779F',
  selectedNotActive: '#6F6E91',
  alertYellow: '#F3B934',
  alertGreen: '#5FD8B4',
  alertRed: '#F520AC',
  borderTabs: '#D3CCFA',
  circleNumbering:'#e2ddfd',
  secondaryBG: '#E9EAF0',
  secondaryIcons: '#B0B4CB',
  secondaryLine: '#EAEDF6',
  secondaryTableHeader: '#EFEEF6',
  secondaryMainTitle: '#303956',
  secondaryCheckbox: '#7279a2',
  smallTitle: '#BFC0C4',
  tooltipIcon: '#8C93B3',
  logoBlue: '#6b50fe',
  lightBlueLogoBorder: '#DED9F7',
  secondaryTextBoxBG: '#E9EDF6',
  secondaryThinLine: '#F2F2FA',
  textColor: '#606996',
  ToUse: '#E6E2FD',
  bgWindow: '#F3F3F9',
  titleTextColor: '#525079',
  tableTitleColor: '#888FB1',
  marketGradeText: '#363E62',
  btnBorderMarketGrade: '#E3E4ED',
  btnBackgroundColor: '#79779D',
  disabledColor: '#AFB4CA',
  backgroundHoverTr: '#F4F1FF',
  itemsPerPage: '#606996',
  menuSelected: '#9D9BB8',
  menuHover: '#615F81',
  clearBtn: '#C8C6E0',
  lightSeparatorLine: '#E8E4EC',
  borderTop: '#EFEFF4',
  borderItemPerPage: 'rgba(175,180,202,0.55)',
  boxShadow: 'rgba(0,0,0,0.06)'
}

const lightTheme: Record<string, unknown> = {
  white: '#fff',
  primaryText: '#626998',
  primaryLogoBlue: '#6D4FFF',
  link: '#666f9a',
  primaryActiveNotSelected: '#79779F',
  selectedNotActive: '#6F6E91',
  alertYellow: '#F3B934',
  alertGreen: '#5FD8B4',
  alertRed: '#F520AC',
  secondaryBG: '#E9EAF0',
  secondaryIcons: '#B0B4CB',
  secondaryLine: '#EAEDF6',
  secondaryTableHeader: '#EFEEF6',
  secondaryMainTitle: '#303956',
  secondaryCheckbox: '#7a789d',
  logoBlue: '#6b50fe',
  lightBlueLogoBorder: '#DED9F7',
  secondaryTextBoxBG: '#E9EDF6',
  secondaryThinLine: '#F2F2FA',
  textColor: '#606996',
  titleTextColor: '#525079',
  tableTitleColor: '#888FB1',
  tableColorPink: '255, 37, 131',
  tableColorGreen: '12, 202, 157',
  marketGradeText: '#363E62',
  disabledColor: '#AFB4CA',
  dialogCard:'#F6F7FC',
  dialogPink: '#FF2583',
  dialogGreen: '#0CCA9D',
  langColor: '#C8C6E2',
  lines: '#ebe4ec',
  menuSelected: '#9D9BB8',
  menuHover: '#615F81',
  clearBtn: '#C8C6E0',
  lightSeparatorLine: '#E8E4EC'
}

export const options: Record<string, unknown> = {
  breakpoint: {
    thresholds: {
      xs: 321,
      sm: 414,
      md: 833,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  directives: { Ripple },
  icons: {
      iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: theme,
      dark: lightTheme
    }
  },
}

export default new Vuetify(options);
