import Vue from 'vue'
import VueTypes from 'vue-types'

import FetchMarketGrade from '../FetchMarketGrade.vue'
import MainMenu from '../MainMenu/MainMenu.vue'

export default Vue.extend({
  name: 'page-wrapper',
  data: () => {
    return {
      options: []
    }
  },
  components: {
    FetchMarketGrade,
    MainMenu
  },
  mounted (): void {
    this.$nextTick(() => {
      this.options = [
        {
          iconComponent: 'IconReporting',
          iconColor: 'var(--v-selectedNotActive)',
          isActive: false,
          label: '',
          key: 'reporting',
          translationKey: 'reporting',
          url: '',
          target: '_self',
        },
        {
          iconComponent: 'IconTools',
          iconColor: 'var(--v-selectedNotActive)',
          isActive: false,
          label: '',
          key: 'tools',
          translationKey: 'tools',
          url: 'tools',
          target: '_self',
        },
        {
          iconComponent: 'IconRecommendations',
          iconColor: 'var(--v-selectedNotActive)',
          isActive: false,
          label: '',
          key: 'recommendations',
          translationKey: 'recommendations',
          url: 'recommendations/insights',
          target: '_self',
        },
        {
          iconComponent: 'IconNewCampaign',
          iconColor: 'var(--v-selectedNotActive)',
          isActive: false,
          label: '',
          key: 'newCampaign',
          translationKey: 'newCampaign',
          url: 'flow/campaigns',
          target: '_self',
        },
        {
          iconComponent: 'IconCampaignCalendar',
          iconColor: 'var(--v-selectedNotActive)',
          isActive: false,
          label: '',
          key: 'campaignCalendar',
          translationKey: 'campaignCalendar',
          url: 'campaign-monitor',
          target: '_self',
        },
        {
          iconComponent: 'IconMarketGrade',
          iconColor: 'var(--v-selectedNotActive)',
          isActive: true,
          label: '',
          key: 'marketGrade',
          translationKey: 'marketGrade',
          url: '#',
          target: '',
        }
      ]
    })
  }
})
