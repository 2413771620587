import { ActionTree, ActionContext, Dispatch, Commit } from 'vuex'
import { State } from './index'

export default <ActionTree<State, any>>{
  SET_USER_TOKEN({ commit, dispatch, state }: any, { token }: any) {
    commit('SET_USER_TOKEN', { token })
  },
  RESET_USER_TOKEN({ commit, dispatch, state }: any) {
    commit('SET_USER_TOKEN', { token: null })
  }
}
