import Vue from 'vue'
import VueTypes from 'vue-types'

export default Vue.extend({
  name: 'svg-icon',
  props: {
    iconName: VueTypes.string.def(''),
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: VueTypes.string.def('currentColor')
  }
})
