import { MutationTree } from 'vuex'
import { State } from './index'

import { MGSort, Paginate } from '../../../mixins/fetchMarketGradeMixin'

interface SetFiltersPayload { type: string, filters: Array<string> }

export default <MutationTree<State>> {
  SET_FILTERS: (state: State, { type, filters }: SetFiltersPayload) => {
    state.filtered = !state.filtered
    state.filters[type] = filters[type]
  },
  SET_TITLE: (state: State, { title }: any) => {
    state.titleChanged = !state.titleChanged
    state.title = title
  },
  SET_EDITABLE: (state: State, { editable }: any) => {
    state.editable = editable
  },
  SET_PAGINATE_SIZE: (state: State, { size }: Paginate) => {
    state.paginate.size = size
    state.paged = !state.paged
  },
  SET_PAGINATE_NUMBER: (state: State, { num }: Paginate) => {
    state.paginate.num = num
    state.paged = !state.paged
  },
  SET_SORT: (state: State, { by, order }: MGSort) => {
    state.sort.by = by
    state.sort.order = order
    state.sorted = !state.sorted
  }
}
