import Vue from 'vue'
import VueTypes from 'vue-types'

import { paginationTranslate } from '../../translations/'

export default Vue.extend({
  name: 'p-pagination',
  data: () => {
    return {
      itemsPerPageOptions: [100, 500, 1000],
    }
  },
  props: {
    page: VueTypes.number.def(1),
    itemsPerPage: VueTypes.number.def(100),
    totalPages: VueTypes.number.def(0)
  },
  watch: {
    page (value: string, oldValue: string): void {
      if (value !== oldValue) {
        this.$nextTick(() => {
          this.$emit('changePageNumber', value)
        })
      }
    },
  },
  computed: {
    pages (): number {
      const { totalPages, itemsPerPage } = this
      return Math.floor(totalPages / itemsPerPage)
    },
  },
  methods: {
    itemsPerPagePlaceholder (): string {
      const { itemsPerPage } = this
      return itemsPerPage !== null && itemsPerPage !== undefined ? itemsPerPage.toString() : '1'
    },
    changePageSize ($event: string): void {
      const { totalPages, itemsPerPage } = this
      const lastPage = Math.floor(totalPages / itemsPerPage)

      this.itemsPerPage = parseInt($event, 10)
      const newLastPage = Math.floor(this.totalPages / this.itemsPerPage)

      this.$emit('pageSizeChange', $event)

      this.$nextTick(() => {
        if (newLastPage < lastPage) {
          this.page= newLastPage
          this.$emit('changePageNumber', newLastPage)
        }
      })
    }
  },
  i18n: paginationTranslate,
})
