import { MGFields } from '@/mixins/fetchMarketGradeMixin'
import Vue from 'vue'
import VueTypes from 'vue-types'

import PSearchableSelect from '@/components/SearchableSelect/PSearchableSelect.vue'
import PPagination from '@/components/Pagination/PPagination.vue'
import PLanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue'
import PDialogCard from '@/components/DialogCard/DialogCard.vue'
import PDialogTitleName from '@/components/DialogTitleName/DialogTitleName.vue'
import PHowToUse from '@/components/HowToUse/HowToUse.vue'
import { marketGradeAnalytics } from '@/plugins/tracking/tracker'
import * as marketGradeAnalyticsType from '@/plugins/tracking/tracking-events'
import { SelectItem } from '@/components/SearchableSelect/pSearchableSelect'
import { marketGradeTranslate } from '@/translations/'

export default Vue.extend({
  name: 'p-market-grade',
  data: () => ({
    heightTable: 500,
    tab: null,
    items: [
      {id: 1, name: 'Report'},
      {id: 2, name: 'How to use'},
    ],
    selected: [],
    headers: [
      { align: 'left', width: '1%', tooltip: false },
      { text: 'Industry', align: 'left', value: 'industry', width: '5%' , class: 'font-weight-medium text-caption pl-0', tooltip: false},
      { text: 'Country', align: 'left', value: 'country', width: '5%', class: 'font-weight-medium text-caption pl-0' , tooltip: false},
      { text: 'Placement', align: 'left', value: 'placement', width: '11%', class: 'font-weight-medium text-caption pl-0', tooltip: false },
      { text: 'Format', align: 'left', value: 'format', width: '6%', class: 'font-weight-medium text-caption pl-0' , tooltip: false},
      { text: 'CPC grade', align: 'left', value: 'cpc', width: '5%', class: 'font-weight-medium text-caption pl-0', tooltip: true, tooltipText: 'CPM market grade' },
      { text: 'CPM grade', align: 'left', value: 'cpm', width: '5%', class: 'font-weight-medium text-caption pl-0', tooltip: true, tooltipText: 'CPC market grade'  },
      { text: 'CTR grade', align: 'left', value: 'ctr', width: '5%', class: 'font-weight-medium text-caption pl-0', tooltip: true, tooltipText: 'CTR market grade'  },
      { text: 'EG grade', align: 'left', value: 'engagement', width: '5%', class: 'font-weight-medium text-caption pl-0', tooltip: true, tooltipText: 'Engagement market grade' },
      { text: 'VTR grade', align: 'left', value: 'vtr', width: '5%', class: 'font-weight-medium text-caption pl-0', tooltip: true, tooltipText: 'VTR market grade'   }
    ],
    selects: [
      {
        text: 'Industry', id: 'industry', multiple: false,  icon: 'IconIndustry', iconHeight: 19, iconWidth: 19, iconColor: 'var(--v-selectedNotActive)'
      },
      {
        text: 'Country', id: 'country', multiple: false, icon: 'IconCountry', iconHeight: 19, iconWidth: 17, iconColor: 'var(--v-selectedNotActive)'
      },
      {
        text: 'Placement', id: 'placement', multiple: true, icon: 'IconPlacement', iconHeight: 25, iconWidth: 25, iconColor: 'var(--v-selectedNotActive)'
      },
      {
        text: 'Format', id: 'format', icon: 'IconFormat', multiple: true, iconHeight: 19, iconWidth: 20, iconColor: 'var(--v-selectedNotActive)'
      }
    ],
    filters: {
      industry: [],
      country: [],
      placement: [],
      format: []
    },
    offset: 0,
    page: 1,
    itemsPerPage: 100,
    sortBy: [],
    sortDesc: [],
  }),
  components: {
    PPagination, PSearchableSelect, PLanguageSelector, PDialogCard, PHowToUse, PDialogTitleName
  },
  props: {
    marketGradeItems: VueTypes.object,
    marketGradeSelectValues: VueTypes.object,
    marketGradeTitle: {
      type: [String, Object],
      default: null
    },
    marketGradeEditable: {
      type: [Boolean, Object],
      default: null
    },
    loading: VueTypes.number.def(0),
    totalPages: VueTypes.number.def(0)
  },
  mounted (): void {
    this.$bus.$on('changeLanguage', (($event: string) => {
      this.fillInHeadersTranslations($event)
    }))
    this.$nextTick(() => {
      this.fillInHeadersTranslations('en')
    })
  },
  computed: {
    filteredData() {
      if(this.marketGradeItems && this.marketGradeItems.page ){
        return this.marketGradeItems.page.filter(d => {
          return Object.keys(this.filters).every(f => {
            return this.filters[f].length < 1 || this.filters[f].includes(d[f])
          })
        })
      }
      return []
    },
    pages (): number {
      const { totalPages, itemsPerPage } = this
      return Math.floor(totalPages / itemsPerPage)
    },
  },
  watch: {
    tab (value: number | null): void {
      if (value === 0) { // Market Grade Tab
        marketGradeAnalytics.track({
          type: marketGradeAnalyticsType.ANALYTICS_EVENT.MARKET_GRADE_ACTION,
          market_grade_action_type: marketGradeAnalyticsType.MARKET_GRADE_ACTION_TYPE.MG_OPEN
        })
      } else {
        marketGradeAnalytics.track({
          type: marketGradeAnalyticsType.ANALYTICS_EVENT.MARKET_GRADE_ACTION,
          market_grade_action_type: marketGradeAnalyticsType.MARKET_GRADE_ACTION_TYPE.MG_HOW_TO
        })
      }
    }
  },
  methods: {
    getSearchableOptionsKey(value: string): string {
      // Verify that this.columnValueList for the specific key was loaded
      // before trying to use it's initial value as our key
      const keyArray = this.columnValueList(value)
      return keyArray?.length > 0 ? keyArray[0] : value
    },
    validateValue (value: number | null): string {
      return (value && value > 0) ? value.toString() : this.$t('notAvailable')
    },
    setMarketGradeTitle (): string {
      const title = typeof this.marketGradeTitle === 'string' ? this.marketGradeTitle : ''
      return title ? title : this.$t('marketGradePageTitle')
    },
    closeWindow (): void {
      this.heightTable = 670
    },
    toggleAll (): void {
      if (this.selected.length) this.selected = []
      else this.selected = this.desserts.slice()
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changeQuery (notificationSelectedItems: any): void {
      const { filters } = this
      const selectedItems = notificationSelectedItems[0]
      const filterId = notificationSelectedItems[1]
      filters[filterId] = [] // Reset changed item

      selectedItems.map((selectItem: SelectItem) => {
        filters[selectItem.parentId].push(selectItem.label)
      })
      this.$nextTick(() => {
        this.$store.dispatch('MarketGradeFilters/SET_FILTERS_CHANGE', { type: filterId, filters }, { root: true })
      })
      const sort = this.$store.state.MarketGradeFilters.sort
      marketGradeAnalytics.track({
        type: marketGradeAnalyticsType.ANALYTICS_EVENT.MARKET_GRADE_ACTION,
        market_grade_action_type: marketGradeAnalyticsType.MARKET_GRADE_ACTION_TYPE.MG_APPLY,
        ...filters,
        ...sort
      })

      this.changePageNumber(1)
    },
    handleOptionsUpdate({ sortBy, sortDesc }): void {
      sortDesc = sortDesc[0] ? 'DESC': 'ASC' // false = 'DESC', true = 'ASC'
      sortBy = sortBy.length === 0 ? MGFields.industry : MGFields[sortBy[0]]

      this.$store.dispatch('MarketGradeFilters/SET_SORT_CHANGE', { by: sortBy, order: sortDesc }, { root: true })
      const sort = this.$store.state.MarketGradeFilters.sort
      const filters = this.$store.state.MarketGradeFilters.filters

      marketGradeAnalytics.track({
        type: marketGradeAnalyticsType.ANALYTICS_EVENT.MARKET_GRADE_ACTION,
        market_grade_action_type: marketGradeAnalyticsType.MARKET_GRADE_ACTION_TYPE.MG_APPLY,
        ...filters,
        ...sort
      })

    },
    columnValueList(val) {
      if (this.marketGradeSelectValues[val] && this.marketGradeSelectValues[val].length) {
        return this.marketGradeSelectValues[val].map(d => d['title'])
      }
    },
    changePageSize ($event: string): void {
      this.itemsPerPage = parseInt($event, 10)
      this.$store.dispatch('MarketGradeFilters/SET_PAGINATE_SIZE', { size: $event }, { root: true })
    },
    changePageNumber ($event: number) :void {
      this.page = $event
      this.$store.dispatch('MarketGradeFilters/SET_PAGINATE_NUMBER', { num: $event }, { root: true })
    },
    getColors (value: number): string {
      const calc = value/100
      if( value > 50 )
        return `rgba(var(--v-tableColorGreen), ${calc})`
      else
        return `rgba(var(--v-tableColorPink), ${1-calc})`
    },
    getColorsText(value: number): string {
      if (value <= 20 || value >= 80 ){
        return 'color:white;'
      }
      else{
        return 'color:black;'
      }
    },
    fillInHeadersTranslations ($event): void {
      const { headers, selects, items } = this
      headers[1].text = this.$t('industry')
      headers[2].text = this.$t('country')
      headers[3].text = this.$t('placement')
      headers[4].text = this.$t('format')
      headers[5].text = this.$t('cpc')
      headers[6].text = this.$t('cpm')
      headers[7].text = this.$t('ctr')
      headers[8].text = this.$t('engagement')
      headers[9].text = this.$t('vtr')
      selects[0].text = this.$t('industry')
      selects[1].text = this.$t('country')
      selects[2].text = this.$t('placement')
      selects[3].text = this.$t('format')
      headers[5].textTooltip = this.$t('cpcTooltip')
      headers[6].textTooltip = this.$t('cpmTooltip')
      headers[7].textTooltip = this.$t('ctrTooltip')
      headers[8].textTooltip = this.$t('engagementTooltip')
      headers[9].textTooltip = this.$t('vtrTooltip')
      items[0].name = this.$t('report')
      items[1].name = this.$t('howToUse')
      this.$emit('fillInHeadersTranslations', $event)
    },
  },
  i18n: marketGradeTranslate,
})
