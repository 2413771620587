import { MGSort, Paginate } from '@/mixins/fetchMarketGradeMixin'
import { GetterTree, Getter } from 'vuex'
import { State } from './index'

export default <GetterTree<State, any>> {
  getFilters (state: State): any {
    return state.filters
  },
  getTitle (state: State): any {
    return state.title
  },
  getEditable (state: State): boolean {
    return state.editable
  },
  getSort (state: State): MGSort {
    return state.sort
  },
  getPaginate (state: State): Paginate {
    return state.paginate
  }
}
