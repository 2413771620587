
export default {
  messages: {
    en: {
      all: 'All',
      apply: 'Apply',
      more: 'more',
      recordCount: 'Record Count',
      clear: 'Clear',
    },
    fr: {
      all: 'Tout',
      apply: 'Appliquer',
      more: 'Suite',
      recordCount: 'Nombre d\'enregistrements',
      clear: 'Dégager',
    }
  }
}
