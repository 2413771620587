import Vue from 'vue'
import Vuetify from 'vuetify'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import VueBus from 'vue-bus';
import Vue2Filters from 'vue2-filters'
import VueFilterDateFormat from 'vue-filter-date-format'
import browserDetect from 'vue-browser-detect-plugin'
import FlagIcon from 'vue-flag-icon'
import { options } from '@/plugins/vuetify' // <== important
import { createProvider } from './vue-apollo'

import "vuetify/dist/vuetify.min.css"
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

Vue.use(Vue2Filters)
Vue.use(VueBus)
Vue.use(FlagIcon)

// TODO: translate all these word values
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday'
  ],
  dayOfWeekNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
})

Vue.use(browserDetect)

// configure Vue to use Vuetify
Vue.use(Vuetify)

// instantiate Vuetify instance with any component/story level params
const vuetify = new Vuetify(options)

new Vue({
  router,
  i18n,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
