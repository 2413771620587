import Vue from 'vue'
import gql from 'graphql-tag'
import { MGFields, MGSort, Paginate } from './fetchMarketGradeMixin'

export interface BrowserInfo {
  type: string;
  name: string;
  version: string;
  userAgent: string;
  isMobile: boolean;
}

export interface MixPanelOptions {
  filters: MGFields;
  sort: MGSort;
  page: Paginate;
}

export interface UserInfo {
  _id: string;
  contact_email: string;
  name: string;
  email: string;
  status: string;
  blocked: boolean;
  company: {
    _id: string;
    name: string;
    label: string;
  }
}

const results = gql`
  query GetUserInfo {
    me {
      _id
      facebook_id
      name
      email
      contact_email
      status
      blocked
      company {
        _id
        name
        label
      }
    }
}`


export default Vue.extend({
  data: (): Record<string, unknown> => {
    return {
      me: {},
      meData: {},
      loading: 0,
      errorMessage: '',
    }
  },
  apollo: {
    me: {
      query: results,
      loadingKey: 'loading',
      error (error: Error): void {
        this.errorMessage = error.message
      }
    }
  },
  watch: {
    me (): void {
      const { me } = this
      if (me) {
        this.meData = me
      }
    }
  },
})
