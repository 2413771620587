import { TrackingProps } from './tracking-events'

(() => {

  // Create a queue, but don't obliterate an existing one!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics: any = window.analytics = window.analytics || []
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) {
      return
  }

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
      if (window.console && console.error) {
          console.error('Segment snippet included twice.')
      }

      return
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
  ]

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = (method: string) => {
      // tslint:disable-next-line:only-arrow-functions
      return function () {
          // const args = Array.prototype.slice.call(arguments)
          // eslint-disable-next-line prefer-rest-params
          const args = Array.from(arguments)
          args.unshift(method)
          analytics.push(args)

          return analytics
      }
  }

  // For each of our methods, generate a queueing stub.
  for (const method of analytics.methods) {
      analytics[method] = analytics.factory(method)
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = (key: string) => {
      // tslint:disable-next-line: max-line-length
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (process.env.VUE_APP_NODE_ENV === 'test' || process.env.VUE_APP_NODE_ENV === 'development' || (window as any).__get$Storage || !window.location.hostname.includes('market-grade.makemereach.com')
      ) {
          return
      } // Do not load the library except in prod
      // Create an async script element based on your key.
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = `${ document.location.protocol === 'https:'
          ? 'https://' : 'http://'
      }cdn.segment.com/analytics.js/v1/${
          key  }/analytics.min.js`

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0]
      if (!first || !first.parentNode) {
          if (window.console && console.error) {
              console.error('Segment snippet could not find where to place analytics.js')
          }

          return
      }
      first.parentNode.insertBefore(
          script,
          first,
      )
  }

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.0.0'

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(process.env.VUE_APP_ANALYTICS_KEY_FRONTEND)

})()

export const identify = (userData: TrackingProps):void => {

  // eslint-disable-next-line prefer-spread
  window.analytics.identify(
    userData.email,
    {
      ...userData,
      domain: 'market-grade',
      last_used: new (Date)
    },
  )
}

export const trackWithUserId = (
  event: string,
    payload: Record<string, unknown> & { user_id?: string, company_id?: string, company_name?: string },
): void => {

  // eslint-disable-next-line prefer-spread
  window.analytics.track.apply(
    window.analytics,
    [
        event,
        payload,
    ] as unknown,
  )
}
