import Vue from 'vue'
import VueTypes from 'vue-types'
import i18n from '@/plugins/i18n'
import { IconLang } from '../Icons'

export default Vue.extend({
    name: 'p-language-selector',
    data: () => ({
      languages: [
        { flag: 'us', language: 'en', title: 'Eng' },
        { flag: 'fr', language: 'fr', title: 'Fr'}
      ],
      toggleIndex: 0,
    }),
    props: {
        headers: VueTypes.array
    },
    components: {
        IconLang
    },
    methods: {
        changeLocale($event: string): void {
          const { languages } = this
          this.toggleIndex = this.toggleIndex === 0 ? 1 : 0
          i18n.locale = languages[this.toggleIndex].language
          this.$nextTick(() => {
            this.$bus.$emit('changeLanguage', $event)
          })
        },
    }
})