






import { Component, Vue } from 'vue-property-decorator';
import FetchMarketGrade from '@/components/FetchMarketGrade.vue'

@Component({
  components: {
    FetchMarketGrade,
  },
})
export default class Home extends Vue {}
