export default {
  messages: {
    en: {
      reporting: 'Reporting',
      tools: 'Tools',
      recommendations: 'Recommendations',
      newCampaign: 'New Campaign',
      campaignCalendar: 'Campaign Calendar',
      marketGrade: 'Market Grade',
    },
    fr: {
      reporting: 'Rapports',
      tools: 'Outils',
      recommendations: 'Recommandations',
      newCampaign: 'Nouvelle campagne',
      campaignCalendar: 'Calendrier de campagne',
      marketGrade: 'Catégorie de marché',
    }
  }
}
