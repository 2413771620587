export default {
  messages: {
    en: {
      marketGradePageDialogCard1: 'Grades are approximate and',
      marketGradePageDialogCard2: 'based on Paragone’s expertise.',
      marketGradePageDialogCard3: 'Paragone is not responsible for',
      marketGradePageDialogCard4: 'the performance of your campaigns. ',
      highest: 'highest',
      high: 'High',
      lowest: 'lowest',
      low: 'Low',
      engagement: 'engagement',
      rate: 'rate',
      performance: 'performance'
    },
    fr: {
      marketGradePageDialogCard1: 'Les notes sont approximatives et',
      marketGradePageDialogCard2: "basé sur l'expertise de Paragone.'",
      marketGradePageDialogCard3: "Paragone n'est pas responsable de",
      marketGradePageDialogCard4: "la performance de vos campagnes.'",
      highest: 'le plus élevé',
      high: 'Haut',
      lowest: 'le plus bas',
      low: 'Bas',
      engagement: 'engagement',
      rate: 'taux',
      performance: 'performance'
    }
  }
}