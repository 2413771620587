import VueTypes from 'vue-types'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export interface UserSettings {
  accounts: {
      [ setting: string ]: any,
  },
  [ setting: string ]: any,
}

// export type UserRights = { [ right in RIGHT ]: true }

interface UserAccount {
  id: string,
  // network: NETWORK,
}

interface Business {
  business_name: string,
  id: string,
}

export interface User {
  id: string,
  facebook_id?: string,
  twitter_id?: string,
  email: string,
  contact_email: string,
  name: string,
  accounts: UserAccount[],
  company_id: string,
  settings: UserSettings | null,
  // rights: UserRights | null,
  // business_user_ids: BusinessUserIds,
  domain: string,
  status: string,
  blocked: boolean,
  suspended: boolean,
  username?: string,
}

export default {
  namespaced: true,
  state (): any {
    return {
      isLoggedIn: VueTypes.bool.def(false),
      token: VueTypes.string.def('')
    }
  },
  actions,
  getters,
  mutations
}

export interface State {
  isLoggedIn: boolean;
  token: string;
}
