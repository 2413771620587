import { tempKey } from '@/vue-apollo'

export const fetchSecurityToken = ((key: string, contentKey: string): string => {
  const cookies = decodeURIComponent(document.cookie).split(';')
  const cookieMap = cookies.map((cookie) => {
    cookie = cookie.trim()
    if (cookie.startsWith(`${key}=`)) {
      cookie = cookie.split('=')[1]
      return cookie
    }
  })

  let cookie = null
  cookieMap.map((oneCookie: any) => {
    if (oneCookie) {
      oneCookie = JSON.parse(oneCookie)

      if (oneCookie.content && oneCookie.content[contentKey]) {
        cookie = oneCookie.content[contentKey]
      }
    }
  })
  const token = cookie ? cookie : tempKey

  return token
})
