import Vue from 'vue'

import { marketGradeCardDialogTranslate } from '../../translations/'

export default Vue.extend({
  name: 'p-dialog-card',
  data: () => {
    return {
      display: true,
      width: 10,
      radius: 1,
      padding: 1,
      lineCap: 'straight',
      value: [0, 0, 0],
      gradientDirection: 'top',
      fill: false,
      type: 'trend',
      autoDraw: false,
      autoLineWidth: false,
      items: [{
        id: 1,
        opacity: 1,
        opacity2: 0.2,
        range: '0-10',
        range2: '50-60',
        class: 'mr-1'
      },
      {
        id: 2,
        opacity: 0.8,
        opacity2: 0.4,
        range: '10-20',
        range2: '60-70',
        class: 'mr-1'
      },
      {
        id: 3,
        opacity: 0.6,
        opacity2: 0.6,
        range: '20-30',
        range2: '70-80',
      },
      {
        id: 4,
        opacity: 0.4,
        opacity2: 0.8,
        range: '30-40',
        range2: '80-90',
        class: 'pr-1'
      },
      {
        id: 5,
        opacity: 0.2,
        range: '40-50',
        range2: '90-100',
        class: ''
      }
    ],
    }
  },
  methods: {
    displayWindow(): void{
      this.display = false
      this.$emit('closeWindow')
    }
  },
  computed: {
    colsByMedia (): number {
      if (this.$vuetify.breakpoint.width > 1380) {
        return 3
      }
      return 2
    },
  },
  i18n: marketGradeCardDialogTranslate,
})
