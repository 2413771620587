export default {
  messages: {
    en: {
      itemsPerPage: 'Items per page:'
    },
    fr: {
      itemsPerPage: 'Objets par page:'
    }
  }
}
