

































import Vue from 'vue'
import VueTypes from 'vue-types'

export default Vue.extend({
  props: {
    fillColor: VueTypes.string.def('currentColor')
  }
})
