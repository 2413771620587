import Vue from "vue"
import Vuex from "vuex"

import MarketGradeFilters from './modules/MarketGradeFilters/'
import User from './modules/User'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    MarketGradeFilters,
    User
  }
})
