
  /**
   * Define event types
   */
  export enum ANALYTICS_EVENT {
      MARKET_GRADE_ACTION = 'MarketGradeAction.S',
  }

  export enum MARKET_GRADE_ACTION_TYPE {
      MG_OPEN = 'mg.open',
      MG_APPLY = 'mg.apply',
      MG_HOW_TO = 'mg.howto',
  }

  type MarketGradeActionPayload = {
      market_grade_action_type: MARKET_GRADE_ACTION_TYPE,
  }

  /**
   * Define events
   */
  type MarketGradeAction = {
      type: ANALYTICS_EVENT.MARKET_GRADE_ACTION,
  } & MarketGradeActionPayload

  /**
   * Define a single type regrouping all events
   */
  export type Event = MarketGradeAction

  export interface TrackingProps {
    id: string;
    company: {
      id: string;
      name: string;
    };
    contact_email: string;
    first_name: string;
    last_name: string;
    email: string;
    browser: string;
    version: string;
  }
