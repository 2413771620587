import { MGSort, Paginate } from '@/mixins/fetchMarketGradeMixin'
import { ActionTree, ActionContext, Dispatch, Commit } from 'vuex'
import { State } from './index'

export default <ActionTree<State, any>>{
  SET_FILTERS_CHANGE({ commit, dispatch, state }: any, { type, filters }: any) {
    commit('SET_FILTERS', { type, filters })
  },
  SET_TITLE_CHANGE({ commit, dispatch, state }: any, { title }: any) {
    commit('SET_TITLE', { title })
  },
  SET_EDITABLE_CHANGE({ commit, dispatch, state }: any, { editable }: any) {
    commit('SET_EDITABLE', { editable })
  },
  SET_SORT_CHANGE({ commit, dispatch, state }: any, { by, order }: MGSort) {
    commit('SET_SORT', { by, order })
  },
  SET_PAGINATE_SIZE({ commit, dispatch, state }: any, { num, size }: Paginate) {
    commit('SET_PAGINATE_SIZE', { num, size })
  },
  SET_PAGINATE_NUMBER({ commit, dispatch, state }: any, { num, size }: Paginate) {
    commit('SET_PAGINATE_NUMBER', { num, size })
  }
}
