import Vue from  'vue'
import gql from 'graphql-tag'

// import fetchSchemaMixin from './fetchSchemaMixin'
export interface MGInput {
  industry?: string[];
  country?: string[];
  placement?: string[];
  format?: string[];
}

const results = gql`
  query MG_Init {
    market_grade_init {
      industry{title}
      country{title}
      placement{title}
      format{title}
      options{title
        editable}
    }
}`

export default Vue.extend({
  data (): Record<string, unknown> {
    return {
      initMarketGrade: [],
      initMarketGradeData: [],
      loading: 0,
      errorMessage: ''
    }
  },
  apollo: {
    market_grade_init: {
      query: results,
      loadingKey: 'loading',
      error (error: Error): void {
        this.errorMessage = error.message
      }
    },
  },
  watch: {
    market_grade_init (): void {
      this.initMarketGradeData = this.market_grade_init
      this.$store.dispatch('MarketGradeFilters/SET_TITLE_CHANGE', { title: this.initMarketGradeData.options.title }, { root: true })
      this.$store.dispatch('MarketGradeFilters/SET_EDITABLE_CHANGE', { editable: this.initMarketGradeData.options.editable }, { root: true })
    },
  },
})
