import { MutationTree } from 'vuex'
import { State } from './index'

interface SetUserPayload { token: string }

export default <MutationTree<State>> {
  SET_USER_TOKEN: (state: State, { token }: SetUserPayload) => {
    state.token = token
    state.isLoggedIn = token !== null
  },
}
