import VueTypes from 'vue-types'
import { MGSort, Paginate } from '../../../mixins/fetchMarketGradeMixin'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state (): any {
    return {
      filters: {
        industry: Array<string>(),
        country: Array<string>(),
        placement: Array<string>(),
        format: Array<string>()
      },
      title: VueTypes.string.def(''),
      editable: VueTypes.bool.def(false),
      sort: Object as () => MGSort,
      paginate: Object as () => Paginate,
      filtered: VueTypes.bool.def(false),
      sorted: VueTypes.bool.def(false),
      paged: VueTypes.bool.def(false),
      titleChanged: VueTypes.bool.def(false),
    }
  },
  actions,
  getters,
  mutations
}

export interface StateFilters {
  industry: Array<string>
  country: Array<string>
  placement: Array<string>
  format: Array<string>
}

export interface State {
  filters: StateFilters;
  title: string;
  editable: boolean;
  sort: MGSort;
  paginate: Paginate;
  filtered: boolean;
  sorted: boolean;
  paged: boolean;
  titleChanged: boolean;
}
