import Vue from 'vue'
import VueTypes from 'vue-types'

import { marketGradeHowToUseTranslate } from '../../translations/'

export default Vue.extend({
    name: 'p-how-to-use',
    data: () => {
      return {
        display: true,
        width: 30,
        height: 70,
        radius: 1,
        padding: 0,
        lineCap: 'straight',
        value: [10, 10, 10],
        gradientDirection: 'top',
        fill: false,
        type: 'trend',
        autoDraw: false,
        autoLineWidth: false,
        items: [{
          id: 1,
          opacity: 1,
          opacity2: 0.2,
          range: '0-10',
          range2: '50-60',
          class: 'mr-1'
        },
        {
          id: 2,
          opacity: 0.8,
          opacity2: 0.4,
          range: '10-20',
          range2: '60-70',
          class: 'mr-1'
        },
        {
          id: 3,
          opacity: 0.6,
          opacity2: 0.6,
          range: '20-30',
          range2: '70-80',
        },
        {
          id: 4,
          opacity: 0.4,
          opacity2: 0.8,
          range: '30-40',
          range2: '80-90',
          class: 'pr-1'
        },
        {
          id: 5,
          opacity: 0.2,
          range: '40-50',
          range2: '90-100',
          class: ''
        }
      ],
      }
    },
    props: {
      marketGradeTitle: VueTypes.string.def(''),
    },
    methods: {
      setMarketGradeTitleInfo (): string {
        return this.marketGradeTitle !== '' ? this.marketGradeTitle : this.$t('howToUseMGTitle2')
      },
    },
    i18n: marketGradeHowToUseTranslate,
  })
