<template>
  <v-app :style="cssProps">
    <v-main class="page-wrapper">
      <page-wrapper />
    </v-main>
  </v-app>
</template>

<script>
  import 'typeface-rubik'
  import { mapGetters } from 'vuex'
  import { fetchSecurityToken } from './utils/localStorage'
  import { openLink } from './utils/navigation'
  import PageWrapper from './components/PageWrapper/PageWrapper.vue'

  export default {
    name: 'app',
    components: {
      PageWrapper
    },
    computed: {
      ...mapGetters([
        'isLoggedIn'
      ]),
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      cssProps () {
        const themeColors = {}
        Object.keys(this.$vuetify.theme.themes.light).forEach(color => {
          themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
        })

        Object.keys(this.$vuetify.theme.themes.dark).forEach(color => {
          themeColors[`--v-${color}`] = this.$vuetify.theme.themes.dark[color]
        })

        return themeColors
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted () {
      const token = fetchSecurityToken('security_token', 'access_token')
      this.$store.dispatch('User/RESET_USER_TOKEN')
      this.$nextTick(() => {
        this.$store.dispatch('User/SET_USER_TOKEN', { token })
        // If the user sin't logged in reroute to the defualt URL
        if (!this.$store.state.User.isLoggedIn) {
          openLink()
        }
      })
    }
  }
</script>

<style lang="sass">

$font-family:'Rubik'

.v-application
  [class*='text-']
    color: var(--v-textColor)
    font-family: $font-family, sans-serif !important
  font-family: $font-family, sans-serif !important

#app
  background: var(--v-secondaryBG)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: var(--v-link)

#nav
  padding: 30px

  a
    font-weight: bold
    color: var(--v-link)

    &.router-link-exact-active
      color: var(--v-alertGreen)

</style>
