
export default {
  messages: {
    en: {
      report : 'Report',
      howToUse : 'How to use',
      marketGradePageTitle: 'Paragone Market Grade Planner',
      filterBy: 'Filter by',
      industry: 'Industry',
      country: 'Country',
      placement: 'Placement',
      format: 'Format',
      cpm: 'CPM grade',
      cpc: 'CPC grade',
      ctr: 'CTR grade',
      vtr: 'VTR grade',
      engagement: 'EG grade',
      notAvailable: 'N/A',
      cpmTooltip: 'Highest CPM is 0, lowest CPM is 100',
      cpcTooltip: 'Highest CPC is 0, lowest CPC is 100',
      ctrTooltip: 'Lowest CTR is 0, highest CTR is 100',
      vtrTooltip: 'Lowest VTR is 0, highest VTR is 100',
      engagementTooltip: 'Lowest engagement rate is 0, highest engagement rate is 100',
      typeToSearch: 'Type to search',
      typeNewName: 'Type new name'
    },
    fr: {
      report : 'Rapport',
      howToUse : 'Comment utiliser',
      marketGradePageTitle: 'Planificateur de qualité du marché Paragone',
      filterBy: 'Filtrer par',
      industry: 'Industrie',
      country: 'Pays',
      placement: 'Placement',
      format: 'Format',
      cpm: 'Noter CPM',
      cpc: 'Noter CPC',
      ctr: 'Noter CTR',
      vtr: 'Noter VTR',
      engagement: 'EG Noter',
      notAvailable: 'N/A',
      cpmTooltip: 'Le CPM le plus élevé est de 0, le CPM le plus bas est de 100',
      cpcTooltip: 'Le CPC le plus élevé est de 0, le CPC le plus bas est de 100',
      ctrTooltip: 'Le CTR le plus bas est de 0, le CTR le plus élevé est de 100',
      vtrTooltip: 'Le VTR le plus bas est 0, le VTR le plus élevé est 100',
      engagementTooltip: "Le d'engagement le plus bas est de 0, le taux d'engagement le plus élevé est de 100",
      typeToSearch: 'Tapez pour rechercher',
      typeNewName: 'Saisir un nouveau nom'
    }
  }
}
