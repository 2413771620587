








































import Vue from 'vue'
import VueTypes from 'vue-types'

import PExternalTable from './MarketGrade/MarketGrade.vue'
import fetchMarketGradeMixin, { MarketGrade } from '@/mixins/fetchMarketGradeMixin'
import fetchUserMixin, { BrowserInfo, UserInfo } from '@/mixins/fetchUserMixin'
import fetchInitMarketGradeMixin from '@/mixins/fetchInitMarketGradeMixin'
import { marketGradeAnalytics } from '@/plugins/tracking/tracker'
import { TrackingProps } from '@/plugins/tracking/tracking-events'

export type TrackingTools = 'mixpanel' | 'segment'

export default Vue.extend({
  name: 'fetch-market-grade',
  components: { PExternalTable },
  mixins: [fetchInitMarketGradeMixin, fetchMarketGradeMixin, fetchUserMixin],
  props: {
    showTable: VueTypes.bool.def(true)
  },
  data: (): Record<string, unknown> => {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 2,
      },
      isFirstTime: true
    }
},
  methods: {
    getMarketGradeTitle (): string {
      return this.$store.state.MarketGradeFilters.title
    },
    getMarketGradeEditable (): boolean {
      return this.$store.state.MarketGradeFilters.editable
    },
    getBrowser(): BrowserInfo {
      const { $browserDetect } = this

      const info: BrowserInfo = {
        type: $browserDetect.meta.name,
        name: 'Chrome',
        version: $browserDetect.meta.version,
        userAgent: $browserDetect.meta.ua,
        isMobile: false,
      }

      if ($browserDetect.isIE) {
        info.name = 'Internet Explorer'
      }

      if ($browserDetect.isChrome) {
        info.name = 'Chrome'
      }

      if ($browserDetect.isFirefox) {
        info.name = 'Firefox'
      }

      if ($browserDetect.isEdge) {
        info.name = 'Microsoft Edge'
      }

      if ($browserDetect.isChromeIOS) {
        info.name = 'Chrome'
        info.isMobile = true
      }

      if ($browserDetect.isIOS) {
        info.name = 'Safari'
        info.isMobile = true
      }

      return info
    }
  },
  watch: {
    marketGrade (value: MarketGrade): void {
      if (value) {
        this.$nextTick(() => {
          this.isFirstTime = false
        })
      }
    },
    me (userData: UserInfo): void {
      if (userData) {
        const { $nextTick, getBrowser } = this
        $nextTick(() => {
          const browser: BrowserInfo = getBrowser()
          const names = userData.name.split(' ')
          const firstName = names.length > 0 ? names[0] : 'No first name'
          const lastName = names.length > 1 ? names[1] : 'No last name'
          const company = {
            id: userData.company._id,
            name: userData.company.name
          }
          const options: TrackingProps = {
            id: userData._id,
            company,
            contact_email: userData.contact_email,
            first_name: firstName,
            last_name: lastName,
            email: userData.email,
            browser: browser.type,
            version: browser.version
          }
          marketGradeAnalytics.identify(options)
        })
      }
    }
  }
})
