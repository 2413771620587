import { BASE_MMR_URL } from '@/vue-apollo'

export interface NavigatingOption {
  iconComponent: string;
  isActive: boolean;
  label: string;
  key: string;
  translationKey: string;
  url: string;
  target: string;
}

export const openLink = (option: Partial<NavigatingOption>): void => {
  if (option && option.url !== undefined && option.url !== '#' ) {
    const link = `${BASE_MMR_URL}${option.url}`
    window.open(link, option.target)
  }
}
