export default {
  messages: {
    en: {
      high: 'High',
      low: 'Low',
      editTitle: 'Edit title',
      howToUseMGTitle: 'How to use',
      howToUseMGTitle2:'the report?',
      marketGradeIs: 'The report dedicated to making media planning faster, easier, and more effective. From this report you can gain insights which placements and ad formats are likely to better attract your audience.',
      paragraph1Part1: 'Pick the industry',
      paragraph1Part2: 'you are going to promote.',
      paragraph2Part1: 'Pick the country which you are going to',
      paragraph2Part2: 'target with your campaign.',
      paragraph3Part1: 'Check Grades or narrow them down by ',
      paragraph3Part2: 'placements or ad format if needed.',
      gradeRelativeTo: 'Grade is a relative metric that shows how effective a certain placement or ad format can be in a specific country for a specific industry. ',
      ourGrades: 'Our grades:',
      gradeList: 'CPC, CPM, CTR, Engagement Rate, VTR',
      commentInEnd: "Grades are approximate and based on Paragone’s expertise and should be considered as a basic guidance. Final results of campaigns fully depend on your execution. Paragone is not responsible for the performance of your campaigns.",
      typeToSearch: 'Type to search',
    },
    fr: {
      howToUseMGTitle: 'Comment utiliser',
      howToUseMGTitle2:'le rapport?',
      editTitle: 'Modifier le titre',
      high: 'Élevé',
      low: 'bas',
      marketGradeIs: "Le rapport dédié à rendre la planification média plus rapide, plus facile et plus efficace. À partir de ce rapport, vous pouvez obtenir des informations sur les emplacements et les formats d'annonces susceptibles de mieux attirer votre public.",
      paragraph1Part1: "Choisissez l'industrie d'un produit que vous êtes",
      paragraph1Part2: 'va promouvoir.',
      paragraph2Part1: 'Choisissez le pays vers lequel vous allez',
      paragraph2Part2: 'cibler avec votre campagne.',
      paragraph3Part1: 'Vérifiez les notes ou réduisez-les par',
      paragraph3Part2: "mplacements ou format d'annonce si nécessaire.",
      gradeRelativeTo: "La note est une mesure relative qui montre l'efficacité d'un certain emplacement ou format d'annonce dans un pays spécifique pour une industrie spécifique.",
      ourGrades: 'Nos notes :',
      gradeList: "CPC, CPM, CTR, taux d'engagement, VTR",
      commentInEnd: "Les notes sont approximatives et basées sur l'expertise de Paragone et doivent être considérées comme un guide de base. Les résultats finaux des campagnes dépendent entièrement de votre exécution. Paragone n'est pas responsable de la performance de vos campagnes.",
      typeToSearch: 'Tapez pour rechercher',
    }
  }
}