import Vue from 'vue'
import VueTypes from 'vue-types'

import { mainMenuTranslate } from '../../translations/'
import { openLink, NavigatingOption } from '@/utils/navigation'

import SvgIcon from '../SvgIcon/SvgIcon.vue'

import {
  IconTools,
  IconCampaignCalendar,
  IconMarketGrade,
  IconNewCampaign,
  IconRecommendations,
  IconReporting
} from '../Icons'

export default Vue.extend({
  name: 'page-wrapper',
  data: () => {
    return {
      expanded: {
        activeColor: 'var(--v-logoBlue)',
        backgroundColor: 'white',
        color: 'var(--v-selectedNotActive)',
        menuWidth: '180px',
        width: '207px',
      },
      collapsed: {
        activeColor: 'var(--v-menuSelected)',
        backgroundColor: 'var(--v-btnBackgroundColor)',
        color: 'white',
        menuWidth: '50px',
        width: '64px',
      },
      innerState: false,
      toggleState: {}
    }
  },
  components: {
    SvgIcon,
    IconTools,
    IconCampaignCalendar,
    IconMarketGrade,
    IconNewCampaign,
    IconRecommendations,
    IconReporting
  },
  props: {
    isOpened: VueTypes.bool.def(true),
    options: {
      type: Array as () => NavigatingOption[],
      default: null
    },
  },
  mounted () {
    const { isOpened, expanded } = this
    // Copy outer value prop so we can alter it
    this.innerState = isOpened
    this.toggleState = expanded
  },
  methods: {
    getBackgroundColor (option: NavigatingOption): string {
      const { innerState, expanded, collapsed } = this
      if (option.isActive) {
        return innerState ? expanded.backgroundColor : collapsed.activeColor
      } else {
        return innerState ? expanded.backgroundColor : collapsed.backgroundColor
      }
    },
    getOptionColor (option: NavigatingOption): string {
      const { innerState, expanded, collapsed } = this
      const notActiveColor = innerState ? expanded.color : collapsed.color
      const activeColor = innerState ? expanded.activeColor : collapsed.color
      return option.isActive ? activeColor : notActiveColor
    },
    toggleExpandCollapse (): void {
      const { expanded, collapsed } = this
      if (this.innerState) {
        this.toggleState = collapsed
      } else {
        this.toggleState = expanded
      }

      this.innerState = !this.innerState
    },
    openLink (option: NavigatingOption): void {
      openLink(option)
    }
  },
  i18n: mainMenuTranslate
})
