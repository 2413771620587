import Vue from 'vue'
import VueTypes from 'vue-types'

import SvgIcon from '../SvgIcon/SvgIcon.vue'
import { IconLogo, IconCountry, IconGraph, IconFormat, IconPlacement, IconIndustry } from '../Icons'

import { pSimpleSelectTranslate } from '../../translations'

export interface SelectItem {
  label: string;
  value: string;
  index: number;
  selected: boolean;
  parentId: string;
}

export default Vue.extend({
  name: 'p-searchable-select',
  data: () => {
    return {
      innerItems: Array<SelectItem>(),
      selectedItems: Array<SelectItem>(),
      displaySelected: '',
      notificationSelectedItems: Array<SelectItem>(),
      filterList: '',
      displayed: false,
      selectAll: false,
      showSelectAll: false,
      showDropDown: false,
      selectSome: false,
      menuOpened: false
    }
  },
  components: {
    SvgIcon,
    IconLogo, IconCountry, IconGraph, IconFormat, IconPlacement, IconIndustry
  },
  props: {
    id: VueTypes.string.def('').isRequired,
    items: VueTypes.array,
    label: VueTypes.string.def(''),
    multiple: VueTypes.bool,
    hint: VueTypes.string.def(''),
    persistentHint: VueTypes.bool.def(false),
    showRecordCount: VueTypes.bool.def(true),
    showCollapsed: VueTypes.bool.def(true),
    iconComponentName: VueTypes.string.def('box'),
    iconColor: VueTypes.string.def('currentColor'),
    iconHeight: VueTypes.number.def(18),
    iconWidth: VueTypes.number.def(18),
    boxColor: VueTypes.string.def(''),
  },
  mounted () {
    const { setSelectedStates, label }  = this
    this.displaySelected = label
    setSelectedStates()

    this.$bus.$on('closeOthers', (($event: string) => {
      this.closeIfNotMe($event)
    }))
  },
  computed: {
    filteredItems (): Array<SelectItem> {
      const { innerItems, filterList, setSelectAllState } = this
      let itemsList = innerItems
      if (filterList !== '') {
        itemsList = innerItems.filter((item: SelectItem) => {
          return item.label.toLowerCase().startsWith(filterList.toLowerCase())
        })
      }

      this.$nextTick(() => {
        setSelectAllState()
      })

      return itemsList
    },
    selectedAllItems (): boolean {
      const { selectedItems, innerItems } = this
      return selectedItems.length === innerItems.length
    },
    selectedSomeItems (): boolean {
      return this.selectedItems.length > 0 && !this.selectedAllItems
    },
  },
  watch: {
    selectedItems () {
      const { selectedItems, label } = this

      this.displaySelected = label

      if (selectedItems.length > 0 && this.multiple) {
        this.displaySelected = `${label} (${selectedItems.length})`
      }
      else{
        this.displaySelected = `${label}`
      }
      this.setSelectAllState()
    },
    displayed (value: boolean) {
      const { id } = this
      if (value === true) {
        this.$bus.$emit('closeOthers', id)
      }
    },
    menuOpened (value: boolean) {
      this.displayed = value
    },
  },
  methods: {
    toggleItemSelected(item): void{
      item.selected = !item.selected
      this.setSelectAllState()
    },
    isMultiple (): boolean {
      return this.multiple
    },
    notifyChange(): void {
      if (this.multiple) {
        this.notificationSelectedItems = this.selectedItems
      } else {
        if(this.selectedItems.length === 0){
          this.notificationSelectedItems = []
        } else {
          this.notificationSelectedItems = [
            { label: this.selectedItems, parentId: this.id }
          ]
        }
      }
      this.$emit('change', [this.notificationSelectedItems, this.id])
    },
    applySelection (): void {
      const { notifyChange } = this
      this.showSelectAll = false
      this.menuOpened = false
      notifyChange()
    },
    clearSelection(): void {
      this.$nextTick(() => {
        const { innerItems, setSelectAllState, applySelection } = this
        if(this.multiple){
          this.selectedAllItems = false
        }
        this.selectedItems = []
        innerItems.forEach((item: SelectItem) => {
          item.selected = false
        })
        applySelection()
        setSelectAllState()

      })
    },
    toggleSelectFiltered (): void {
      this.$nextTick(() => {
        const { innerItems, filteredItems, setSelectAllState } = this

        let value = false
        const currentFilter = filteredItems.slice()
        if (this.selectedSomeItems) {  //selectedAllItems - if all items selected
          let filteredItem: SelectItem
          currentFilter.map((item: SelectItem) => {
            innerItems.filter((innerItem: SelectItem) => {
              if ((item.parentId === innerItem.parentId) &&
                (item.label === innerItem.label)) {
                  filteredItem = innerItem
                  innerItem.selected = !innerItem.selected
                }
            })

            const selectedItemIndex = this.selectedItems.map((selectedItem: SelectItem, index: number) => {
              if ((selectedItem.parentId === filteredItem.parentId) &&
                (selectedItem.label === filteredItem.label)) {
                  return index
                }
            })

            this.selectedItems.splice(selectedItemIndex, 1)
          })
          // this.selectedItems = []
        } else {
          this.selectedItems = currentFilter
          value = true
        }

        innerItems.forEach((item: SelectItem) => {
          if(this.selectedItems.length === 0 ){
            item.selected = value
          }else{
            this.selectedItems.forEach((item2: SelectItem) => {
              if(item2.parentId === item.parentId && item2.label === item.label)
                item.selected = value
            })
          }

        })
        setSelectAllState()
      })
    },
    toggleSelectAll (): void {
      this.$nextTick(() => {
        const { innerItems, filteredItems, filterList, setSelectAllState, toggleSelectFiltered } = this

        if (filterList.length > 0) {
          toggleSelectFiltered()
        } else {
          let value = false
          if (this.selectedAllItems) {  //selectedAllItems - if all items selected
            this.selectedItems = []
          } else {
            this.selectedItems = filteredItems.slice()
            value = true
          }

          innerItems.forEach((item: SelectItem) => {
            if(this.selectedItems.length === 0 ){
              item.selected = value
            }else{
              this.selectedItems.forEach((item2: SelectItem) => {
                if(item2.parentId === item.parentId && item2.label === item.label)
                  item.selected = value
              })
            }

          })
        }
        setSelectAllState()
      })
    },
    setSelectAllState (): void {
      const { selectedAllItems, selectedSomeItems } = this
      if( this.multiple){
        // Default settings
        this.selectAll = false
        this.selectSome = false

        if (selectedAllItems) {
          this.selectAll = true
          this.selectSome = false
        } else if (selectedSomeItems) {
          this.selectAll = false
          this.selectSome = true
        }
      }
    },
    setSelectedStates (): void {
      const { items, innerItems, id } = this

      items.forEach((item: string, index: number) => {
        innerItems.push({
          label: item,
          index,
          selected: false,
          parentId: id
        })
      })
    },
    closeIfNotMe ($event: string) : void {
      const { id } = this
      if ($event !== id) {
        this.displayed = false
      }
    },
    getDisplayedBoxStyle (displayed: boolean): string {
      if (displayed) {
        return '; box-shadow: 0px 0px 0px 5px var(--v-lightBlueLogoBorder) !important;'
      }

      return ''
    }
  },
  i18n: pSimpleSelectTranslate
})
