import Vue from 'vue'
import gql from 'graphql-tag'

import { marketGradeHowToUseTranslate } from '../../translations'

const changeTitleMutation = gql`
    mutation MG_Change_Title ($title: String!) {
      SetMarketGrade(title: $title)
}`

export default Vue.extend({
    name: 'p-dialog-title-name',
    data: () => {
      return {
        dialog: false,
        currentSrc: 0,
        titleValue: '',
        loading: 0,
        errorMessage: '',
      }
    },
    methods:{
      changeImage () {
        if (this.currentSrc === 0) {
          this.currentSrc = 1
          return 0
        }else
          this.currentSrc = 0
          return 1
      },
      changeTitle (): void {
        this.$apollo.mutate({
          mutation: changeTitleMutation,
          variables: { title: this.titleValue },
          loadingKey: 'loading',
          error (error: Error): void {
            this.errorMessage = error.message
          }
        })
        this.$store.dispatch('MarketGradeFilters/SET_TITLE_CHANGE', { title: this.titleValue }, { root: true })
        this.dialog = false
      },
    },
    i18n: marketGradeHowToUseTranslate,
  })
