import Vue from  'vue'
import VueTypes from 'vue-types'
import gql from 'graphql-tag'

import { MGInput } from './fetchInitMarketGradeMixin'

// import fetchSchemaMixin from './fetchSchemaMixin'

export enum MGFields {
  industry = 'industry',
  country = 'country',
  placement = 'placement',
  format = 'format',
  cpc = 'cpc',
  cpm = 'cpm',
  ctr = 'ctr',
  engagement = 'engagement',
  vtr = 'vtr'
}

export enum OrderWay {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface MGSort {
  by: MGFields,
  order: OrderWay
}
export interface Paginate {
  num: number;
  size: number;
}

export interface MarketGrade {
  country: string;
  cpc: number;
  cpm: number;
  ctr: number;
  engagement: number;
  format: string;
  industry: string;
  placement: string;
  vtr: number;
}

const results = gql`
  query marketGrade ($page: Paginate!, $filter: MGInput, $order: MGSort) {
    marketGrade(page: $page, filter: $filter, order: $order) {
      page {
        industry
        country
        placement
        format
        cpc
        cpm
        ctr
        engagement
        vtr
      }
      total
  }
}`

export default Vue.extend({
  data: (): Record<string, unknown> => {
    return {
      marketGrade: [],
      marketGradeData: [],
      loading: 0,
      errorMessage: '',
      pageCount: 0,
      total: 0,
      num: 0,
      size: 100,
      filters: []
    }
  },
  props: {
    by: VueTypes.string.def('industry'),
    orderWay: VueTypes.string.def('ASC')
  },
  apollo: {
    marketGrade: {
      query: results,
      variables (): Record<string, unknown> {
        const { filtersChanged, pageChanged, sortChanged } = this
        const page: Paginate = pageChanged

        const order: MGSort = sortChanged

        const filter: MGInput = filtersChanged

        return {
          page, filter, order
        }
      },
      loadingKey: 'loading',
      error (error: Error): void {
        this.errorMessage = error.message
      }
    }
  },
  watch: {
    marketGrade (): void {
      const { marketGrade } = this
      if (marketGrade ) {
        this.marketGradeData = marketGrade.page
        this.total = marketGrade.total
      }
    }
  },
  computed: {
    filtersChanged (): MGInput {
      const state = this.$store.state.MarketGradeFilters.filters
      const filter: MGInput = {}

      // Cover all cases
      if (state.industry && state.industry.length > 0) {
        filter.industry = state.industry
      }
      if (state.country && state.country.length > 0) {
        filter.country = state.country
      }
      if (state.placement && state.placement.length > 0) {
        filter.placement = state.placement
      }
      if (state.format && state.format.length > 0) {
        filter.format = state.format
      }

      return filter
    },
    sortChanged (): MGSort {
      // This line is what fires the change - DO NOT REMOVE IT
      this.$store.state.MarketGradeFilters.sorted
      const state = this.$store.state.MarketGradeFilters.sort
      const by = MGFields[state.by]
      const orderBy = state.order === OrderWay.DESC ? OrderWay.DESC : OrderWay.ASC

      // Set default value to sort
      const order: MGSort = {
        by: MGFields.industry,
        order: OrderWay.ASC
      }

      if (by && by.length > 0) {
        order.by = by
      }

      if (orderBy && orderBy.length > 0) {
        order.order = orderBy
      }

      return order
    },
    pageChanged (): Paginate {
      // This line is what fires the change - DO NOT REMOVE IT
      this.$store.state.MarketGradeFilters.paged

      const state = this.$store.state.MarketGradeFilters.paginate
      const num = state.num
      const size = state.size

      // Set default paginate value
      const newPage: Paginate = {
        num: 0,
        size: 100
      }

      const fixedNum = num === 0 ? num : num -1

      newPage.num = fixedNum || 0

      if (size) {
        newPage.size = size
      }

      return newPage
    }
  }
})
