import { GetterTree, Getter } from 'vuex'
import { State } from './index'

export default <GetterTree<State, any>> {
  getLoginState (state: State): boolean {
    return state.isLoggedIn
  },
  getToken (state: State): string {
    return state.token
  }
}
