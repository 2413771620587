import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { fetchSecurityToken } from '@/utils/localStorage'
import { setContext } from 'apollo-link-context'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

const currentURL = location.href
var referrer = document.referrer || location.href;

// If no production file is found go to Integration/Dev
let productionURL = false
let preProductionURL = false
let stagingURL = false
let localhostURL = false

switch (referrer) {
  case process.env.VUE_APP_MMR_PARAGONE_PRODUCTION:
    productionURL = true
    break
  case process.env.VUE_APP_MMR_PARAGONE_STAGING:
    stagingURL = true
    break
  case process.env.VUE_APP_PARAGONE_INTEGRATION:
    preProductionURL = true
    break
  case "http://localhost:8081/":
      localhostURL = true
    break
}

let DEFAULT_URL
let BASE_URL_PATH
let BASE_MMR_URL_PATH
export let tempKey

if (productionURL) {
  DEFAULT_URL = process.env.VUE_APP_CG_PRODUCTION
  BASE_URL_PATH = process.env.VUE_APP_PARAGONE_PRODUCTION
  BASE_MMR_URL_PATH = process.env.VUE_APP_MMR_PARAGONE_PRODUCTION
  tempKey = ''
} else if (stagingURL) {
  DEFAULT_URL = process.env.VUE_APP_CG_STAGING
  BASE_URL_PATH = process.env.VUE_APP_PARAGONE_STAGING
  BASE_MMR_URL_PATH = process.env.VUE_APP_MMR_PARAGONE_STAGING
  tempKey = ''
} else if (localhostURL || preProductionURL) {
  DEFAULT_URL = process.env.VUE_APP_CG_INTEGRATION
  BASE_URL_PATH = process.env.VUE_APP_PARAGONE_INTEGRATION
  BASE_MMR_URL_PATH = process.env.VUE_APP_PARAGONE_INTEGRATION
  tempKey = process.env.VUE_APP_TEMP_KEY
}

export const BASE_URL = BASE_URL_PATH
export const BASE_MMR_URL = BASE_MMR_URL_PATH

// Http endpoint, removed Storybook references
const httpEndpoint = DEFAULT_URL || process.env.VUE_APP_CG_INTEGRATION

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const appToken = fetchSecurityToken('security_token', 'access_token')

  const token = `Bearer ${appToken}`

  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || ''
    }
  }
})

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  link: authLink

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
  // ...options
})

// Call this in the Vue app file
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createProvider () {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console

      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })
  return apolloProvider
}
